<!--
 * @Description: 文件描述
 * @Date: 2020-05-22 11:06:08
 * @LastEditTime: 2020-05-27 10:52:54
 * @LastEditors: 白青
-->
<template>
  <a-form layout="inline" :form="form" @submit="handleFormSubmit">
    <a-form-item>
      <a-select default-value="title" style="width: 120px" @change="handleSearchTypeChange" :form="form" @submit="handleFormSubmit">
        <a-select-option value="title">页面标题</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item>
      <a-input v-model.trim="searchParams.page_title" placeholder="请输入页面标题" allowClear />
    </a-form-item>
    <a-form-item>
      <a-button type="primary" html-type="submit" :loading="loading">查询</a-button>
    </a-form-item>

  </a-form>
</template>

<script>
export default {
  name: 'TopicRegManageSearchBar',
  props: {
    searchParams: {
      default: function() {
        return {};
      },
      type: Object
    },
    loading: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      form: this.$form.createForm(this),
    }
  },
  methods: {
    // 搜索类型切换回调
    handleSearchTypeChange () {

    },
    // 表单提交
    handleFormSubmit (e) {
      e.preventDefault();
      this.form.validateFields((err) => {
        if (!err) {
          this.$emit('callback', this.searchParams)
        }
      });
    }
  }
}
</script>

<style>

</style>
