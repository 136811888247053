<!--
 * @Description: 专题报名管理
 * @Date: 2020-05-22 10:51:53
 * @LastEditTime: 2020-08-09 15:57:51
 * @LastEditors: 矢车
-->
<template>
  <div id="topicRegManage">
    <TopicRegManageSearchBar :searchParams="searchParams" @callback="handleSearchCallback" :loading="listLoading" />
    <a-table :scroll="{ x: 1200, y: tableScrollY }" v-auth="'operate_manage_projects_apply_page'" :rowKey="record => record.id" :columns="Columns" :dataSource="list" :pagination="pagination" :loading="listLoading" :locale="{ emptyText: '暂无专题' }" @change="handleTableChange">
      <template slot="deadlineTime" slot-scope="record">
        <span>{{record.apply_deadline_time ? record.apply_deadline_time.substr(0, 16) : ''}}</span>
      </template>
      <template slot="operation" slot-scope="record">
        <a-button size="small" class="mr-10" @click="handleShowTopicPreview(record.id)">查看</a-button>
      </template>
    </a-table>
  </div>
</template>

<script>
import TopicRegManageSearchBar from "@/components/admin/operate/topic-reg-manage-search-bar";
import { getTopics } from "@/service/topic";
// 专题管理列表
const Columns = [
  {
    title: "专题ID",
    dataIndex: "id",
    align: "left",
    fixed: 'left',
    width: 160,
  },
  {
    title: "页面标题",
    dataIndex: "page_title",
    align: "left",
    width: 500,
  },
  {
    title: "报名人数",
    dataIndex: "apply_num",
    align: "center",
    width: 160,
  },
  {
    title: "报名截止",
    // dataIndex: "apply_deadline_time",
    align: "center",
    width: 160,
    scopedSlots: { customRender: "deadlineTime" },
  },
  {
    title: "操作",
    align: "center",
    fixed: 'right',
    width: 160,
    scopedSlots: { customRender: "operation" },
  },
];

export default {
  name: "TopicRegManage",
  components: {
    TopicRegManageSearchBar,
  },
  data() {
    return {
      Columns,
      tableScrollY: 600,
      listLoading: true,
      list: [],
      searchParams: {
        page_title: "",
        is_projects_apply: true,
      },
      pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        showTotal: (total) =>
          `共 ${total} 条记录 第 ${this.pagination.current}/${Math.ceil(
            total / this.pagination.pageSize
          )} 页`,
        showQuickJumper: true,
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) =>
          (this.pagination.pageSize = pageSize),
      },
    };
  },
  created() {
    var windowHeight = document.documentElement.clientHeight || 1000;
    this.tableScrollY = windowHeight - 280;
  },
  mounted() {
    this.getTopicList();
  },
  methods: {
    // 搜索回调
    handleSearchCallback(searchParams) {
      this.searchParams = searchParams;
      this.getTopicList();
    },
    // 获取专题列表
    async getTopicList() {
      // TODO
      // if (!checkPer("operate_manage_projects_list")) {
      //   this.$message.error("无权限获取列表");
      //   return false;
      // }
      const data = Object.assign(
        { page: this.pagination.current, page_size: this.pagination.pageSize },
        this.searchParams
      );
      this.listLoading = true;
      const { err, res } = await getTopics(data);
      this.listLoading = false;
      if (!err) {
        if (res.success) {
          const pagination = { ...this.pagination };
          this.list = res.data.results;
          pagination.total = res.data.count;
          this.pagination = pagination;
        } else {
          this.$message.warning(res.message);
        }
      }
    },
    // 点击查看报名详情
    handleShowTopicPreview(id) {
     /* this.$router.push({
        path: "/admin/operate/topicRegDetail",
        query: { id },
      });*/
      var path='/admin/operate/topicRegManage/' + id;
      this.$router.push({
        path:path
      });

    },
    // 分类
    handleTableChange(pagination) {
      if (pagination) this.pagination.current = pagination.current;
      this.getTopicList();
    },
  },
};
</script>

<style lang='less' scoped>
  #topicRegManage {
  /deep/ .ant-table-body {
     //样式穿透
     overflow-x: auto !important;
   }
    .ant-table-body {
      //样式穿透
      overflow-x: auto !important;
    }
}
</style>
